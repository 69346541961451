<template>
  <div class="gallery-component__wrapper">
    <h3>
      <slot name="title"></slot>
    </h3>
    <div class="gallery-component__content">
      <div class="flex-row gap-30">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ComponentGalleryTemplate",
}
</script>