<template>
  <div>
    <ComponentGalleryTemplate>
      <template #title>Input with label</template>
      <TextFormElement
          :element="{...inputElement, id: 1}"
          :focus="focus"
          v-on:focus="focus = $event"
          v-on:modified="inputElement.value = $event;"
      />
      <TextFormElement
          :element="{...inputElement, id: 2}"
          :focus="focus"
          :passed-value="passedValue"
          :error="true"
          v-on:focus="focus = $event"
          v-on:modified="inputElement.value = $event;"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Input with placeholder</template>
      <TextFormElement
          :element="{...inputElement, id: 3}"
          :focus="focus"
          :hideLabel="true"
          v-on:focus="focus = $event"
          v-on:modified="inputElement.value = $event;"
      />
      <TextFormElement
          :element="{...inputElement, id: 4}"
          :focus="focus"
          :passed-value="passedValue"
          :error="true"
          :hideLabel="true"
          v-on:focus="focus = $event"
          v-on:modified="inputElement.value = $event;"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Dropdown with label</template>
      <DropdownFormElement
          :element="{...dropdownElement, id: 1}"
          :focus="focus"
          :passed-value="initialFilter"
      />
      <DropdownFormElement
          :element="{...dropdownElement, id: 2}"
          :error="true"
          :focus="focus"
          :passed-value="{...initialFilter, text: 'Input Text'}"
      />
    </ComponentGalleryTemplate>
  </div>
</template>

<script>
import TextFormElement from "../FormElements/TextFormElement.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import ComponentGalleryTemplate from "./ComponentGalleryTemplate.vue";

export default {
  name: "FormulareGallery",
  components: {ComponentGalleryTemplate, DropdownFormElement, TextFormElement},
  data() {
    return {
      focus: null,
      passedValue: 'Input Text',
      inputElement: {
        fieldLabel: 'Label',
        fieldType: 'Text',
        field: 'test',
      },
      dropdownElement: {
        fieldValue: '',
        options: [{
          text: 'Test 1',
          value: 'Test 1'
        },
          {
            text: 'Test 2',
            value: 'Test 2'
          }
        ],
        fieldLabel: 'Label'
      },
      initialFilter: {
        text: '',
        value: 'All'
      }
    }
  },
}
</script>