// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.layout-container[data-v-1d27ad64] {
  display: flex;
}
.main-content[data-v-1d27ad64] {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/MeinGenios/MeinGeniosMain.vue"],"names":[],"mappings":";AAsDA;EACA,aAAA;AACA;AAEA;EACA,OAAA;AACA","sourcesContent":["<template>\n  <div class=\"layout-container\">\n    <LeftNavigationDrawerComponent\n        v-if=\"!isMobile\"\n        :title=\"$t('genios.menu')\"\n        :menu-items=\"meinGeniosMenuItems\"\n        :is-mobile=\"isMobile\"\n    />\n\n    <div class=\"main-content\">\n      <router-view />\n    </div>\n  </div>\n</template>\n\n<script>\nimport LeftNavigationDrawerComponent from \"./NavigationDrawers/LeftNavigationDrawerComponent.vue\";\n\nexport default {\n  name: 'MeinGeniosMain',\n  components: {\n    LeftNavigationDrawerComponent,\n  },\n  computed: {\n    isMobile() {\n      return this.windowWidth <= 850;\n    },\n    meinGeniosMenuItems() {\n      return this.$store.getters['getMeinGeniosNavItems'];\n    }\n  },\n  data() {\n    return {\n      isLoading: false,\n      windowWidth: window.innerWidth,\n      windowHeight: window.windowHeight,\n    };\n  },\n  methods: {\n    handleResize() {\n      this.windowWidth = window.innerWidth;\n      this.windowHeight = window.innerHeight;\n    },\n  },\n  mounted() {\n    window.addEventListener('resize', this.handleResize);\n  },\n  beforeDestroy() {\n    window.removeEventListener('resize', this.handleResize);\n  },\n}\n</script>\n\n<style scoped>\n.layout-container {\n  display: flex;\n}\n\n.main-content {\n  flex: 1;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
