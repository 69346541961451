import { render, staticRenderFns } from "./FormulareGallery.vue?vue&type=template&id=58528e04"
import script from "./FormulareGallery.vue?vue&type=script&lang=js"
export * from "./FormulareGallery.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports