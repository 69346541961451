<template>
  <div class="divider vertical-resizable-divider">
    <Button
        type="button"
        variant="secondary"
        :icon="sizePanel === 'open' ? 'chevron-back-outline' : 'chevron-forward-outline'"
        :toggle-text="false"
        @click.stop="togglePanel"
        class="divider-button"
        aria-label="hide"
    />
    <div class="slider" ref="slider" @mousedown.stop="onDragStart" @click.stop="onClick"></div>
  </div>
</template>


<script>
import Button from "./styled/Button.vue";
import store from "../../functions/store/modules";
import _ from "lodash";

export default {
  name: "VerticalDraggableDivider",
  components: {Button},
  props: {
    sizePanel: String,
    closeLeftSide: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      divider: null,
      initialDividerPosition: 0,
      minDividerPosition: 30,
      initialSidenavWidth: 0,
      dividerPosition: 0,
      isDragging: false,
    }
  },
  computed: {
    storedPosition: function () {
      return store.getters.getSearchResultSideNavLastPosition;
    },
  },
  mounted() {
    this.divider = document.querySelector(".divider");
    this.debouncedResize = _.debounce(this.handleResize, 100);
    window.addEventListener("resize", this.debouncedResize);
    this.$nextTick(() => {
      this.handleResize();
    })
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedResize);
    document.removeEventListener('mousemove', this.handleDragging);
    document.removeEventListener('mouseup', this.onDragEnd);
    store.commit('setSearchResultSideNavLastPosition', null);
  },
  methods: {
    calculateInitialValues() {
      const viewportWidth = window.innerWidth;
      const fudge1 = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--fudge1'));
      const tiles = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--tiles'));
      const fudge2 = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--fudge2'));
      const collapsed_sidenav_size = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--collapsed_sidenav_size'));
      if (viewportWidth >= 1000) {
        this.initialSidenavWidth = collapsed_sidenav_size + ((viewportWidth - fudge1) / tiles - 1) - fudge2 + 20;
      } else if (viewportWidth > 600 && viewportWidth < 1000) {
        const desktopWidth = collapsed_sidenav_size + ((viewportWidth - fudge1) / tiles - 1) - fudge2 + 2;
        this.initialSidenavWidth =  desktopWidth <= viewportWidth / 2 ? desktopWidth : viewportWidth / 2;
      } else {
        this.initialSidenavWidth = viewportWidth - 34;
      }
      this.initialDividerPosition = this.initialSidenavWidth;
    },
    handleResize(event) {
      this.calculateInitialValues();
      if (event && event.type === "resize") {
        store.commit('setSearchResultSideNavLastPosition', null);
      }
      if (this.sizePanel === 'closed') {
        this.setDividerAtPosition(this.minDividerPosition);
      } else {
        if (this.storedPosition) {
          this.setDividerAtPosition();
        } else {
          this.positionDivider();
        }
      }
    },
    handleDragging (event) {
      this.isDragging = true;
      if (this.sizePanel === 'closed') {
        this.onDragEnd();
      } else {
        const width = window.innerWidth;
        let newPosition = 0;
        if (event) {
          const percentage = (event.pageX / width) * 100;
          if (percentage >= 10 && percentage <= 90) {
            newPosition = (percentage.toFixed(2) / 100) * width;
          }
        }
        if (newPosition < (width/2)) {
          this.initialDividerPosition = newPosition === 0 ? 30 : newPosition;
          this.positionDivider(event);
        }
        if (this.sizePanel === 'open') {
          const marginLeft = this.getMarginLeft(this.divider);
          store.commit('setSearchResultSideNavLastPosition', marginLeft > this.initialSidenavWidth ? marginLeft : null)
        }
      }
    },
    onDragStart() {
      this.isDragging = false;
      document.addEventListener('mousemove', this.handleDragging);
      document.addEventListener('mouseup', this.onDragEnd);
    },
    onDragEnd() {
      if (this.dividerPosition < this.initialSidenavWidth) {
        store.commit('setSearchResultSideNavLastPosition', this.initialSidenavWidth);
        this.togglePanel();
      } else {
        store.commit('setSearchResultSideNavLastPosition', this.dividerPosition);
      }

      document.removeEventListener('mousemove', this.handleDragging);
      document.removeEventListener('mouseup', this.onDragEnd);
      setTimeout(() => (this.isDragging = false), 0);
    },
    positionDivider(event) {
      const width = window.innerWidth;
      if (this.initialDividerPosition > 0 && this.initialSidenavWidth <= this.initialDividerPosition && this.initialDividerPosition < width / 2) {
        this.dividerPosition = this.initialDividerPosition;
        this.setDividerAtPosition(this.dividerPosition);
      }
      if (this.initialDividerPosition <= this.initialSidenavWidth) {
       this.dividerPosition = this.initialDividerPosition <= this.minDividerPosition ? this.minDividerPosition : this.initialDividerPosition;
        this.divider.style.marginLeft = this.dividerPosition+'px';
        if (!event) {
          this.$emit("divider-position", this.dividerPosition);
        }
      }
    },
    getMarginLeft(divider) {
      const computedStyle = window.getComputedStyle(divider);
      return parseFloat(computedStyle.marginLeft);
    },
    togglePanel() {
      this.divider.classList.add('smooth');
      const currentSizePanel = this.sizePanel;
      if (currentSizePanel === 'open') {
        this.setDividerAtPosition(this.minDividerPosition);
      } else {
        if (this.storedPosition) {
          this.setDividerAtPosition();
        } else {
          this.calculateInitialValues();
          this.dividerPosition = this.initialDividerPosition > 0 ? this.initialDividerPosition : this.minDividerPosition;
          this.setDividerAtPosition(this.dividerPosition);
        }
      }
      this.divider.addEventListener('transitionend', this.removeSmoothClass);
      this.$emit("toggle-sidebar");
    },
    removeSmoothClass() {
      this.divider.classList.remove('smooth');
      this.divider.removeEventListener('transitionend', this.removeSmoothClass);
    },
    setDividerAtPosition(position) {
      const newPosition = position ? position : this.storedPosition;
      this.divider.style.marginLeft = newPosition+'px';
      this.$emit("divider-position", newPosition);
    },
    onClick(event) {
      if (this.isDragging) {
        event.preventDefault();
      }
    }
  },
  watch: {
    sizePanel(newValue) {
      const slider = this.$refs.slider;
      if (slider) {
        slider.classList.toggle("not-draggable", newValue !== "open");
      }
    }
  },
};
</script>

<style scoped>

</style>
