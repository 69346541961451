<template>
  <div>
    <ComponentGalleryTemplate>
      <template #title>Secondary Filled</template>
      <Button
          type="submit"
          variant="secondary--filled"
          :toggle-text="false"
          :text="buttonText"
          @click="clicked"
      />
      <Button
          type="submit"
          variant="secondary--filled"
          :toggle-text="false"
          :disabled="true"
          :text="buttonText"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Primary</template>
      <Button
          variant="primary"
          type="button"
          :toggle-text="false"
          :text="buttonText"
          @click="clicked"
      />
      <Button
          variant="primary"
          type="button"
          :toggle-text="false"
          :disabled="true"
          :text="buttonText"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Primary loading</template>
      <Button
          variant="primary"
          type="button"
          :toggle-text="false"
          :text="loading ? 'Click to stop loader' : 'Click to start loader'"
          :loading="loading"
          @click="submit"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Secondary</template>
      <Button
          variant="secondary"
          type="button"
          :toggle-text="false"
          :text="buttonText"
          @click="clicked"
      />
      <Button
          variant="secondary"
          type="button"
          :toggle-text="false"
          :disabled="true"
          :text="buttonText"
          @click="clicked"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Secondary icon & text</template>
      <Button
          type="button"
          variant="secondary"
          :toggle-text="false"
          icon="alarm-outline"
          :text="buttonText"
          position="left"
          @click="clicked"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Secondary icon</template>
      <Button
          type="button"
          variant="secondary"
          :toggle-text="false"
          @click="clicked"
      >
        <ion-icon src="/icons/custom/chevron-back-outline.svg"/>
      </Button>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Icon buttons</template>
      <Button
          type="button"
          variant="icon"
          border="none"
          @click="clicked"
      >
        <ion-icon src="/icons/custom/chevron-back-outline.svg"/>
      </Button>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Login Buttons associate with permissions</template>
      <p>This is a screenshot of LoginButtons.vue</p>
      <img src="/screenshots/login-buttons.png" alt="Search" class="responsive-image"/>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Checkbox</template>
      <CheckboxFormElement
          :element="{
        id: 'unselected checkbox',
        }"
          :passed-value="false"
      />
      <CheckboxFormElement
          :element="{
        id: 'selected checkbox',
        }"
          :passed-value="true"
      />
      <CheckboxFormElement
          :element="{
        id: 'error checkbox',
        }"
          :error="true"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Radio Buttons</template>
      <RadioGroupComponent
          :group="[{
            fieldLabel: '',
            value: ''
          }]"
          :initial-value="null"
          direction="column"/>
      <RadioGroupComponent
          :group="[{
            fieldLabel: '',
            value: 'key'
          }]"
          :initial-value="'key'"
          direction="column"/>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Radio Buttons</template>
      <RadioGroupComponent
          :group="[{
            fieldLabel: 'Label',
            value: ''
          }]"
          :initial-value="null"
          direction="column"/>
      <RadioGroupComponent
          :group="[{
            fieldLabel: 'Label',
            value: 'key'
          }]"
          :initial-value="'key'"
          direction="column"/>
    </ComponentGalleryTemplate>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import RadioGroupComponent from "../FormElements/radio/RadioGroupComponent.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import ComponentGalleryTemplate from "./ComponentGalleryTemplate.vue";
import {openTransparentPopupModal} from "../../../functions/opens";
import LoginButtons from "../Login/LoginButtons.vue";
import EPaperDownloadButton from "../previewPane/EPaperDownloadButton.vue";
import CheckBoxFilter from "../Filters/CheckBoxFilter.vue";

export default {
  name: "ButtonGallery",
  components: {
    CheckBoxFilter,
    EPaperDownloadButton,
    LoginButtons,
    ComponentGalleryTemplate, DropdownFormElement, RadioGroupComponent, CheckboxFormElement, Button},
  data() {
    return {
      buttonText: 'Button Text',
      loading: false,
      filterParams: {
        category: {
          title: 'categories',
          searchPlaceholder: 'filterBy',
          maxNumber: 10
        },
        region: {
          title: 'region',
          searchPlaceholder: 'filterBy',
          maxNumber: 10
        }
      },
    }
  },
  methods: {
    clicked: function () {
      openTransparentPopupModal();
      const contentContainer = document.querySelector("#overlay_content");
      contentContainer.innerHTML = "<h4>Button was clicked</h4>";
    },
    submit: function () {
      this.loading = !this.loading;
    }
  }
}
</script>