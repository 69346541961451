<template>
  <div id="header__login__buttons" v-click-outside="clickOutside">
    <span v-if="this.showEmail" class="login__person__text">{{ userEmail }}</span>
    <Button
        :icon="calculateIcon"
        id="person__button"
        variant="icon"
        @click="toggleExpanded(!expanded)"
        type="button"
        style="margin-left: 5px;"
    />
    <div v-show="expanded" class="login__options">
      <Button
          v-if="(supportsWebUser && !hasPermission('can_edit_registration_data')) && hasPermission('settings')"
          variant="primary"
          name="login"
          border="none"
          icon="person-outline"
          aria-label="person"
          @click="login();toggleExpanded(false)"
          :text="$t('genios.login.buttons.login')"
          :text-style="400"
          :toggle-text="false"
          type="button"
          position="left"
      />
      <Mode @click="closeExpanded" />
      <Button
          v-if="loggedIn && displayGalleryButton()"
          variant="primary"
          :text="$t('genios.login.buttons.gallery')"
          name="gallery"
          type="button"
          position="left"
          border="none"
          :text-style="400"
          :toggle-text="false"
          icon="gift-open-outline"
          class="not_for_payment"
          @click="navigateToComponentGallery()"
      />
      <Button
          v-if="showLogoutButton"
          id="logout__button"
          variant="primary"
          @click="logout();toggleExpanded(false)"
          name="logout"
          border="none"
          :text="$t('genios.login.buttons.logout')"
          :text-style="400"
          :toggle-text="false"
          type="button"
          icon="key-outline"
          position="left"
      />
      <Button
          v-else-if="!loggedIn"
          id="login__button"
          variant="primary"
          name="login"
          @click="login();toggleExpanded(false)"
          border="none"
          :text='$t("genios.login.buttons.login")'
          :text-style="400"
          :toggle-text="false"
          type="button"
          icon="key-outline"
          position="left"
      />
    </div>
    <RightNavigationDrawerComponent
        v-if="!isMobile && !isMeinGeniosCollapsed && meinGeniosMenuItems"
        :menu-items="meinGeniosMenuItems"
        class="login__options"
    />
    <Button
        v-if="loggedIn"
        :icon="'settings-outline'"
        aria-label="mein-genios"
        id="mein_genios__button"
        variant="icon"
        @click="toggleNavigation"
    />
    <div v-if="isMobile && !isMeinGeniosCollapsed" class="drawer-overlay">
      <LeftNavigationDrawerComponent
          :isMobile="true"
          :title="$t('genios.menu')"
          :menu-items="meinGeniosMenuItems"
          class="nav"
          @close-nav="handleCloseNav"
          v-click-outside="closeDrawer"
      />
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

import {closeFullPopupModal, closeTransparentPopupModal} from "../../../functions/closing";
import updatePrices from "../../../functions/updatePrices";

import Button from "../styled/Button.vue";
import Mode from "./Mode.vue";
import LeftNavigationDrawerComponent from "../MeinGenios/NavigationDrawers/LeftNavigationDrawerComponent.vue";
import RightNavigationDrawerComponent from "../MeinGenios/NavigationDrawers/RightNavigationDrawerComponent.vue";
import {setupLoginComponent} from "../../../functions/setups/vue-components";
import {fetchNavigationTree, fetchUsersWatchlistBookmarks} from "../../../functions/fetching";
import {openTransparentPopupModal} from "../../../functions/opens";
import {hasPermission} from "../../../functions/utils/permission_utils";
import store from "../../../functions/store/modules";

export default {
  name: "LoginButtons",
  components: { Mode, Button, LeftNavigationDrawerComponent, RightNavigationDrawerComponent },
  data() {
    return {
      expanded: false,
      windowWidth: window.innerWidth,
      meinGeniosMenuItems: [],
      isMeinGeniosCollapsed: true,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    sharedState: Object
  },
  computed: {
    userEmail: function () {
      return this.sharedState.defaultUserEmail || "";
    },
    loginStatus: function () {
      if (!!this.sharedState && !!this.sharedState.loginStatus) {
        return this.sharedState.loginStatus;
      }
      return {loggedIn: false, supportsWebUser: false};
    },
    loggedIn: function () {
      return this.loginStatus.loggedIn;
    },
    supportsWebUser: function () {
      return this.loginStatus.supportsWebUser;
    },
    isMobile() {
      return this.windowWidth <= 850;
    },
    isExternalAuth() {
      //it must be external auth if we detect a loginAccount
      return !!this.sharedState.ebibList?.at(0)?.loginAccount;
    },
    calculateIcon() {
      if (!this.loggedIn) {
        return 'person-outline';
      }

      if (this.supportsWebUser) {
        //they are part of a group, but are they the groupUser or a webUser?
        return hasPermission("can_edit_registration_data") ? 'people' : 'people-outline';
      }

      //if they can not edit registration data, assume it is a groupUser (w/o webuser), otherwise it's just a person
      return hasPermission("can_edit_registration_data") ? 'person' : 'people-outline';
    },
    showEmail() {
      if (this.loggedIn) {
        return hasPermission("can_edit_registration_data") // otherwise it's a groupUser
      }
      return false
    },
    showLogoutButton() {
      if (this.loggedIn) {
        // real user, not a groupUser - or external can always log out.
        return hasPermission("can_edit_registration_data") || this.isExternalAuth
      }
      return false
    },
  },
  watch: {
    expanded(newValue) {
      if (newValue) {
        this.isMeinGeniosCollapsed = true;
      }
    },
    isMeinGeniosCollapsed(newValue) {
      if (!newValue) {
        this.expanded = false;
      }
    },
    loggedIn: async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.$store.dispatch('fetchMeinGeniosNavItems');

        await fetchNavigationTree();
        updatePrices();
        //this.updateDefaultUserEmail();
        if (newValue === true) {
          fetchUsersWatchlistBookmarks();
          await store.dispatch('fetchSearchPurposeList');

          this.meinGeniosMenuItems = this.$store.getters['getMeinGeniosNavItems'];
        }
      }
    },
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('fetchMeinGeniosNavItems');

      if (this.loggedIn) {
        await this.$store.dispatch('fetchSearchPurposeList');
        await this.$store.dispatch('fetchBookmarkList');
        await this.$store.dispatch('setInitialActiveWatchlist');
      }

      this.$data.meinGeniosMenuItems = this.$store.getters['getMeinGeniosNavItems'];
    });
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    clearUserWatchlistBookmarks() {
      window.sharedState.watchlistDocumentIds = null;
      sessionStorage.setItem('watchlistDocumentIds', null);
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      if (this.expanded) {
        this.isMeinGeniosCollapsed = true;
      }
    },
    toggleNavigation() {
      this.isMeinGeniosCollapsed = !this.isMeinGeniosCollapsed;
      if (!this.isMeinGeniosCollapsed) {
        this.expanded = false;
      }
    },
    closeExpanded() {
      this.expanded = false;
    },
    clickOutside() {
      this.expanded = false;
      this.isMeinGeniosCollapsed = true;
    },
    login: function () {
      closeFullPopupModal(false, false);
      let callback = function () {
        closeTransparentPopupModal();
      };
      if (this.sharedState.page === "index") {
        callback = function () {
          setTimeout(function () {
            window.location.replace("/");
          }, 2000);
        };
      }

      if (this.sharedState.pathRoot === "document") {
        callback = function () {
          location.reload();
        };
      }

      const contentContainer = document.querySelector("#overlay_content");
      contentContainer.innerHTML = "<div id='embedded_login'></div>";
      openTransparentPopupModal();
      setupLoginComponent("#embedded_login", "genios.login.reasons.login", callback);
    },
    logout: async function () {
      closeFullPopupModal(false, false);
      closeTransparentPopupModal();
      this.clearUserWatchlistBookmarks();
      sessionStorage.clear();

      window.location.assign('/logout');
    },
    hasPermission: function (name) {
      return hasPermission(name);
    },
    closeDrawer(event) {
      if (!event.target.closest('.drawer')) {
        this.isMeinGeniosCollapsed = true;
      }
    },
    handleCloseNav() {
      this.isMeinGeniosCollapsed = true;
    },
    navigateToComponentGallery: async function () {
      window.location.href = "/componentGallery";
    },
    displayGalleryButton: function () {
      return !(this.sharedState.path === "/componentGallery") && this.sharedState.canAccessGallery;
    }
  }
};
</script>

<style scoped>
#header__login__buttons {
  display: flex;
  align-items: center;
  position: relative;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
