<template>
  <div>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: #FFFFFF">
        <p>#FFFFFF</p>
        <p>$bg-standard: var(--background-color)</p>
      </div>
      <div class="color-gallery" style="background-color: #7F9ED0">
        <p>#7F9ED0</p>
        <p>$secondary-color: var(--secondary-color)</p>
      </div>
      <div class="color-gallery" style="background-color: #4479BE">
        <p>#4479BE</p>
        <p>$secondary-dark: var(--secondary-dark)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: #F5F5F5">
        <p>#F5F5F5</p>
        <p>$shade-light: var(--shade-light)</p>
      </div>
      <div class="color-gallery" style="background-color: #7F9ED0">
        <p>#7F9ED0</p>
        <p>$secondary-color: var(--secondary-color)</p>
      </div>
      <div class="color-gallery" style="background-color: #005EB1">
        <p>#005EB1</p>
        <p>$secondary-darker: var(--secondary-darker)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: #BDBDBD">
        <p>#BDBDBD</p>
        <p>$shade-color: var(--shade-color)</p>
      </div>
      <div class="color-gallery" style="background-color: #EAF0FF">
        <p>#EAF0FF</p>
        <p>$secondary-light: var(--secondary-light)</p>
      </div>
      <div class="color-gallery" style="background-color: #EAF0FF">
        <p>#EAF0FF</p>
        <p>$secondary-light: var(--secondary-light)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: #757575">
        <p>#757575</p>
        <p>$shade-dark: var(--shade-dark)</p>
      </div>
      <div class="color-gallery" style="background-color: #F98F17">
        <p>#F98F17</p>
        <p>$primary-color: var(--primary-color)</p>
      </div>
      <div class="color-gallery" style="background-color: #F38014">
        <p>#F38014</p>
        <p>$primary-dark: var(--primary-dark)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: #424242">
        <p>#424242</p>
        <p>$shade-darker: var(--shade-darker)</p>
      </div>
      <div class="color-gallery" style="background-color: #F98F17">
        <p>#F98F17</p>
        <p>$primary-color: var(--primary-color)</p>
      </div>
      <div class="color-gallery" style="background-color: #EC7112">
        <p>#EC7112</p>
        <p>$primary-darker: var(--primary-darker)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: #000000">
        <p>#000000</p>
        <p>$fg-standard: var(--foreground-color)</p>
      </div>
      <div class="color-gallery" style="background-color: #FEE1B4">
        <p>#FEE1B4</p>
        <p>$primary-light: var(--primary-light)</p>
      </div>
      <div class="color-gallery" style="background-color: #FEE1B4">
        <p>#FEE1B4</p>
        <p>$primary-light: var(--primary-light)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: #CC1D33">
        <p>#CC1D33</p>
        <p>$danger-color: var(--danger-color)</p>
      </div>
      <div class="color-gallery" style="background-color: #BF152C">
        <p>#BF152C</p>
        <p>$danger-dark: var(--danger-dark)</p>
      </div>
      <div class="color-gallery" style="background-color: #B00020">
        <p>#B00020</p>
        <p>$danger-darker: var(--danger-darker)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: #F5F5F5">
        <p>#F5F5F5</p>
        <p>$shade-dark: var(--shade-dark)</p>
      </div>
      <div class="color-gallery" style="background-color: #7F9ED0">
        <p>#7F9ED0</p>
        <p>$secondary-color: var(--secondary-color)</p>
      </div>
      <div class="color-gallery" style="background-color: #005EB1">
        <p>#005EB1</p>
        <p>$secondary-darker: var(--secondary-darker)</p>
      </div>
    </ComponentGalleryTemplate>
  </div>
</template>

<script>
import ComponentGalleryTemplate from "./ComponentGalleryTemplate.vue";

export default {
  name: "ColorsGallery",
  components: {ComponentGalleryTemplate},
}
</script>

<style scoped>
.color-gallery {
  width: 33%;
  padding: 30px;
  border: 1px solid #BDBDBD;
}
</style>