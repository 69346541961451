<template>
  <div>
    <ComponentGalleryTemplate>
      <template #title>Range Filter</template>
      <RangeFormElement
          :key="inputElement.fieldLabel"
          :element="inputElement"
          :passedValue="passedValue"
          :focus="focus"
          v-on:focus="focus = $event"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Popup</template>
      <Button
          type="button"
          variant="primary"
          :text="'Click to open popup'"
          @click="openPopup()"
      />
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Schedule</template>
      <div class="enter">
        <schedule-sub-form
            :focused="focus"
            :schedule="schedule"
            :shared-state="{loginStatus: true}"
            :emails-group-list="[]"
            @focus="focus = $event"
            @update-schedule="schedule = {...schedule, ...$event}"
        />
      </div>
    </ComponentGalleryTemplate>
  </div>
</template>

<script>
import ComponentGalleryTemplate from "./ComponentGalleryTemplate.vue";
import RangeFormElement from "../FormElements/RangeFormElement.vue";
import Button from "../styled/Button.vue";
import {openTransparentPopupModal} from "../../../functions/opens";
import ScheduleSubForm from "../Monitoring/ScheduleSubForm.vue";
import {formatDate} from "../../../functions/utils/date_utils";

export default {
  name: "OtherGallery",
  components: {ScheduleSubForm, Button, RangeFormElement, ComponentGalleryTemplate},
  data() {
    return {
      focus: null,
      passedValue: {
        from: formatDate(new Date().toISOString()),
        to: formatDate(new Date().toISOString())
      },
      inputElement: {
        id: 1,
        fieldLabel: 'Years',
        fieldType: 'RANGE',
        field: '',
      },
      schedule: {
        active: true,
        emails: [],
        sendMode: "DAY_MASK",
        dayMask: 31,
        dailyHour: (new Date).getHours(),
        dayOfMonth: 1,
        showProximity: true,
        decompound: true,
        layout: "NEWSLETTER",
        noOfHits: 10
      },
    }
  },
  methods: {
    openPopup: function (){
      openTransparentPopupModal();
      const contentContainer = document.querySelector("#overlay_content");
      contentContainer.innerHTML = "<h2>This is a test popup</h2>";
    }},
}
</script>