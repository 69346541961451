<template>
  <div class="tabs">
    <div
        v-for="tab in tabs"
        :key="tab.name"
        :class="'tab'"
        @click="selectTab(tab.name)"
    >
      <span :class="{ active: selectedTab === tab.name }">
        {{ tab.label }}
      </span>
      <div v-if="tab.subtabs && selectedTab === tab.name" class="subtabs">
        <div
            v-for="subtab in tab.subtabs"
            :key="subtab.name"
            :class="['subtab', { active: selectedSubtab === subtab.name }]"
            @click.stop="selectSubtab(subtab.name)"
        >
          <span>
            {{ subtab.label }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationDrawer",
  props: {
    tabs: Array,
    value: {
      type: String,
      required: true,
    },
    subvalue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: this.value,
      selectedSubtab: this.subvalue,
    };
  },
  methods: {
    selectTab(tabName) {
      if (this.selectedTab === tabName) {
        this.selectedTab = '';
        this.selectedSubtab = '';
      } else {
        this.selectedTab = tabName;
        this.selectedSubtab = this.tabs.find(tab => tab.name === this.selectedTab).subtabs[0].name || '';
      }
      this.$emit('input', this.selectedTab);
      this.$emit('subinput', this.selectedSubtab);
    },
    selectSubtab(subtabName) {
      this.selectedSubtab = subtabName;
      this.$emit('subinput', subtabName);
    },
  },
  watch: {
    value(newVal) {
      this.selectedTab = newVal;
    },
    subvalue(newVal) {
      this.selectedSubtab = newVal;
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  flex-direction: column;
}

.tab {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-bottom: 2px solid transparent;
}
.tab > .active {
  font-weight: 700;
  font-size: 16px;
  transition: all ease-in-out;
  color: var(--primary-darker);
}

.subtabs {
  margin-left: 20px;
}

.subtab {
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}

.subtab.active, .subtab:hover {
  transition: all ease-in-out;
  border-bottom: 1px solid var(--primary-color);
  color: var(--primary-dark);
}
</style>
