// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.image-container[data-v-77cb9adb] {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.responsive-image[data-v-77cb9adb] {
  display: block;
  max-width: 100%;
  height: auto;
}
`, "",{"version":3,"sources":["webpack://./src/main/frontend/javascript/vue/components/ComponentGallery/NavigationGallery.vue"],"names":[],"mappings":";AAqBA;EACA,qBAAA;EACA,UAAA;EACA,SAAA;AACA;AAEA;EACA,cAAA;EACA,eAAA;EACA,YAAA;AACA","sourcesContent":["<template>\n  <div>\n    <div class=\"image-container\">\n      <ComponentGalleryTemplate>\n        <template #title>MenuNavigation.vue</template>\n        <img src=\"/screenshots/navigation-menu.png\" alt=\"Navigation Menu\" class=\"responsive-image\"/>\n      </ComponentGalleryTemplate>\n    </div>\n  </div>\n</template>\n\n<script>\nimport ComponentGalleryTemplate from \"./ComponentGalleryTemplate.vue\";\n\nexport default {\n  name: \"NavigationGallery\",\n  components: { ComponentGalleryTemplate }\n}\n</script>\n\n<style scoped>\n.image-container {\n  display: inline-block;\n  padding: 0;\n  margin: 0;\n}\n\n.responsive-image {\n  display: block;\n  max-width: 100%;\n  height: auto;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
