<template>
  <mein-genios-template :pageTitle="$t('meinGenios.bookmarks.title')" :page-info="$t('meinGenios.bookmarks.info')">
    <template v-slot:variants>
      <BookmarkListBox
          :watchlists="watchlists"
          :bookmark-list="bookmarkList"
          :selected-bookmark-list="selectedBookmarkList"
          @modified="updateSelectedBookmarkList"
      />
    </template>
    <template v-slot:content>
      <header-layout
          v-if="bookmarksArray.length > 0"
          :action-bar-config="actionBarConfig"
          @remove-all="removeAll"
          @update:selected-all="selectAll($event)"
          @updated:filter-and-sort="filterAndSort($event)"
          @update-prices="updatePrices($event)"
          @update-list="updateList"
      ></header-layout>
      <div class="list-view-wrapper" v-if="bookmarksArray.length > 0">
        <div class="list-container list-view-switcher list-view">
          <ArticleElementComponent
              v-for="(item, index) in bookmarksArray"
              :key="item.documentId + index"
              :document="item"
              :isSelected="selectedBookmarks.map(doc => doc.documentId).includes(item.documentId)"
              :index="index"
              :view="'listView'"
              :sort-by="sort"
              :action-bar-config="actionBarConfig"
              @update-selected="updateSelectedBookmarks"
              @update-prices="updatePrices($event)"
              @update-list="updateList"
          />
          <pagination-component
              v-if="pagesQuantity > 1"
              :pages="pagesQuantity"
              :selected="currentPage"
              @update-page="updateCurrentPage"
          />
        </div>
      </div>
      <div v-else>
        <empty-content-placeholder :icon="'bookmark-outline'" :message="$t('meinGenios.bookmarks.empty')" :is-loading="isLoading"></empty-content-placeholder>
      </div>
    </template>
  </mein-genios-template>
</template>

<script>
import DropdownFormElement from "../../FormElements/DropdownFormElement.vue";
import ArticleElementComponent from "../../styled/ArticleElementComponent.vue";
import PaginationComponent from "../../styled/PaginationComponent.vue";
import MeinGeniosTemplate from "../MeinGeniosTemplate.vue";
import HeaderLayout from "../common/HeaderLayout.vue";
import BookmarkList from "./BookmarkList.vue";
import {ActionBarType, BookmarksSortAndOrder} from "../../../../functions/consts";
import bookmarksModule from "../../../../functions/store/modules/bookmark";
import BookmarkListBox from "./BookmarkListBox.vue";
import {updateDocumentPrices} from "../../../../functions/fetching";
import EmptyContentPlaceholder from "../../styled/EmptyContentPlaceholder.vue";
import {queryParamMixin} from "../../../../functions/setups/vue-components/queryParamMixin";
import eventBus from "../../../../eventBus";

export default {
  name: "BookmarkComponent",
  components: {
    EmptyContentPlaceholder,
    HeaderLayout,
    MeinGeniosTemplate,
    PaginationComponent,
    ArticleElementComponent,
    BookmarkList,
    DropdownFormElement,
    BookmarkListBox
  },
  mixins: [queryParamMixin],
  data() {
    return {
      moduleName: 'bookmarksModule',
      focus: null,
      deleting: false,
      isProcessing: false,
      dropdownActionBarButtons: ActionBarType.DROPDOWN_BOOKMARK,
      actionBarButtons: ActionBarType.BOOKMARK,
    };
  },
  computed: {
    watchlists() {
      return this.$store.getters['getBookmarkList'] || [];
    },
    bookmarkList() {
      return (
          this.watchlists.map(item => ({
            text: `${item.name} (${item.numberOfBookmarks})`,
            id: item.id,
          })) || []
      );
    },
    selectedBookmarkList() {
      return this.$store.getters['getSelectedBookmarkList'];
    },
    activeWatchListIds() {
      return this.$store.getters['getActiveWatchlistIds'];
    },
    isAllSelected() {
      return this.bookmarksArray.length > 0 && this.bookmarksArray.every(bookmark => this.selectedBookmarks.map(doc => doc.documentId).includes(bookmark.documentId));
    },
    selectedBookmarks() {
      return this.$store.getters['bookmarksModule/getSelectedBookmarks']
    },
    bookmarksArray() {
      return this.$store.getters['bookmarksModule/getBookmarks']?.map(item => ({
        ...item,
        isBookmark: true
      })) || [];
    },
    pagesQuantity() {
      return (this.$store.getters['bookmarksModule/getTotalNumberOfBookmarks'] || 0 )
    },
    size() {
      return this.$store.getters['bookmarksModule/getSortSize'];
    },
    sort() {
      return this.$store.getters['bookmarksModule/getSortBy'];
    },
    order() {
      return this.$store.getters['bookmarksModule/getSortOrder'];
    },
    currentPage() {
      return this.$store.getters['bookmarksModule/getCurrentPage'];
    },
    isLoading() {
      return this.$store.getters['bookmarksModule/getLoading']
    },
    actionBarConfig() {
      return {
        dropdownActionBarButtons: this.dropdownActionBarButtons,
        actionBarButtons: this.actionBarButtons,
        selectedArticles: this.selectedBookmarks,
        isAllSelected: this.isAllSelected,
        allDocumentsOnPage: this.bookmarksArray,
        shouldDisplaySortBar: true,
        settingsBar: {
          sizeSelected: this.size,
          sortAndOrder: {
            selectedOption: {
              sort: this.sort,
              order: this.order,
            },
            optionsArray: BookmarksSortAndOrder
          }
        },
      }
    }
  },
  async created() {
    if (!this.$store.hasModule('bookmarksModule')) {
      this.$store.registerModule('bookmarksModule', bookmarksModule);
    }
  },
  async mounted() {
    const activeWatchlistIds = this.$store.getters['getActiveWatchlistIds']
    const activeWatchlist = activeWatchlistIds.length === 0 ? {} : this.$store.getters['getBookmarkList'].find(
        (list) => list.id === activeWatchlistIds[0]
    ) || {};
    const activeListObj = {
      id: activeWatchlist.id,
      text: `${activeWatchlist.name} (${activeWatchlist.numberOfBookmarks || 0})`
    };

    if (!this.selectedBookmarkList?.id) {
      await this.$store.commit('setSelectedBookmarkList', activeListObj);
    }

    this.$nextTick(async () => {
      const query = this.getQueryParams();
      this.saveQueryParamsToStore(query);
      if (query) {
        await this.loadBookmarks();
      }
      this.updateRoute();
    });
    eventBus.$on("update-list", async () => {
      await this.updateList();
    })
  },
  methods: {
    async loadBookmarks() {
      const listId = this.selectedBookmarkList?.id || null;
      if (listId) {
        await this.$store.dispatch('bookmarksModule/fetchBookmarks', listId);
      }
    },
    async updateSelectedBookmarkList(event) {
      this.$store.commit('bookmarksModule/setSelectedBookmarks', []);
      this.$store.commit('bookmarksModule/setCurrentPage', 1);
      await this.$store.commit('setSelectedBookmarkList', event);
      await this.updateRoute();
    },
    async updateCurrentPage(page) {
      if (page !== this.currentPage) {
        this.$store.commit('bookmarksModule/setCurrentPage', page);
        this.updateRoute();
      }
    },
    updateSelectedBookmarks({ documentId, selected }) {
      const selectedBookmarks = this.$store.getters['bookmarksModule/getSelectedBookmarks'];
      const newSelectedBookmarks = this.bookmarksArray?.find(item => item.documentId === documentId);
      if (selected && newSelectedBookmarks) {
        this.$store.commit('bookmarksModule/setSelectedBookmarks', Array.from(
            new Set([...selectedBookmarks, newSelectedBookmarks])));
      } else {
        this.$store.commit('bookmarksModule/setSelectedBookmarks', selectedBookmarks.filter(item => item.documentId  !== documentId));
      }
    },
    async updatePrices(ids) {
      const documentsToUpdate = this.bookmarksArray.filter(item => ids.includes(item.documentId));
      const updatedBookmarks = await updateDocumentPrices(ids, documentsToUpdate);
      const mergedBookmarks = this.bookmarksArray.map(bookmark => {
        const updatedBookmark = updatedBookmarks.find(b => b.documentId === bookmark.documentId);
        return updatedBookmark ? {...bookmark, ...updatedBookmark} : bookmark;
      });
      this.$store.commit('bookmarksModule/setBookmarks', mergedBookmarks);
    },
    async updateList() {
      const oldPage = this.currentPage;
      this.$store.commit('bookmarksModule/setBookmarks', []);
      this.$store.commit('bookmarksModule/setSelectedBookmarks', []);
      this.$store.commit('bookmarksModule/setCurrentPage', 1);
      this.updateRoute();
      if (oldPage === 1) {
        await this.loadBookmarks();
      }
    },
    removeAll() {
      this.$store.commit('bookmarksModule/setSelectedBookmarks', []);
      this.$store.commit('bookmarksModule/setCurrentPage', 1);
    },
    selectAll(event) {
      if (event) {
        this.$store.commit('bookmarksModule/setSelectedBookmarks', [...this.selectedBookmarks, ...this.bookmarksArray]);
      } else {
        const remainingSelectedBookmarks = [
          ...this.selectedBookmarks.filter(item1 => !this.bookmarksArray.some(item2 => item1.documentId === item2.documentId)),
          ...this.bookmarksArray.filter(item2 => !this.selectedBookmarks.some(item1 => item1.documentId === item2.documentId))
        ]
        this.$store.commit('bookmarksModule/setSelectedBookmarks', remainingSelectedBookmarks);
      }
    },
    async filterAndSort(event) {
      this.$store.commit('documentHistoryModule/setCurrentPage', 1);
      this.$store.commit('bookmarksModule/setSortBy', event.sort);
      this.$store.commit('bookmarksModule/setSortOrder', event.order);
      this.$store.commit('bookmarksModule/setSortSize', event.size);
      this.updateRoute();
    },
    updateRoute() {
      if (this.selectedBookmarkList?.id) {
        this.syncQueryParams({
          listId: this.selectedBookmarkList.id,
          page: this.currentPage,
          size: this.size,
          sort: this.sort,
          order: this.order
        });
      }
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      async handler(query) {
        if (query && this.$store.hasModule(this.moduleName)) {
          this.saveQueryParamsToStore(query);
          await this.loadBookmarks();
        }
      },
    },
    watchlists(newValue) {
      const activeWatchlistIds = this.activeWatchListIds;
      const activeWatchlist = activeWatchlistIds.length === 0 ? {} : newValue.find(
          (list) => list.id === activeWatchlistIds[0]
      ) || {};
      const activeListObj = {
        id: activeWatchlist.id,
        text: activeWatchlist.name
      }

      if (!this.selectedBookmarkList?.id) {
        this.$store.commit('setSelectedBookmarkList', activeListObj);
      }

      this.$nextTick(async () => {
        const query = this.getQueryParams();
        this.saveQueryParamsToStore(query);
        if (query) {
          await this.loadBookmarks();
        }
        this.updateRoute();
      });
      eventBus.$on("update-list", async () => {
        await this.updateList();
      })
    },
    activeWatchListIds(newValue) {
      const activeWatchlist = newValue.length === 0 ? {} : this.watchlists.find(
          (list) => list.id === newValue[0]
      ) || {};
      const activeListObj = {
        id: activeWatchlist.id,
        text: activeWatchlist.name
      }

      if (!this.selectedBookmarkList?.id) {
        this.$store.commit('setSelectedBookmarkList', activeListObj);
      }

      this.$nextTick(async () => {
        const query = this.getQueryParams();
        this.saveQueryParamsToStore(query);
        if (query) {
          await this.loadBookmarks();
        }
        this.updateRoute();
      });
      eventBus.$on("update-list", async () => {
        await this.updateList();
      })
    },
  },
};
</script>

<style scoped>
</style>
