<template>
  <div class="watchlistCollection">
    <WarningAlertComponent v-if="warningVisible" :message-type="'standard-list-deletion'"/>

    <div
        v-for="(watchlist) in watchlists"
        :key="watchlist.id"
        class="list__item"
    >
      <div class="checkbox-label-actionbar-wrapper">
        <div class="checkbox-label-wrapper">
          <checkbox-form-element
              :element="{...watchlist, fieldLabel: ''}"
              @modified="toggleSelection(watchlist.id, $event)"
          />
          <span class="custom-label">
            {{ watchlist.name }} ({{ watchlist.numberOfBookmarks }})
          </span>
        </div>

        <ActionBar
            :action-bar-buttons="watchlist.name === 'Standard-Merkliste' ? ['clear', 'download-list', 'mail-list'] : ['clear', 'edit', 'delete-list', 'download-list', 'mail-list']"
            :selected-list="[watchlist]"
            :all-documents-on-page="watchlists"
            :inputInitialValue="watchlist.name"
        />
      </div>
    </div>

    <div class="button-section-divider"></div>

    <div class="button-section">
      <div class="header-layout--action-bar--layout">
        <div class="pill">
          <SelectedArticlesToggler
              ref="selectArticlesToggler"
              v-show="selectedLists.length"
              :expanded="false"
              :amount="selectedLists.length"
              :type="'bookmark-list'"
          />
          <ActionBar
              :action-bar-buttons="['delete-list', 'clear']"
              :selected-list="selectedLists"
              :all-documents-on-page="watchlists"
              @standard-merkliste-selected="handleStandardMerklisteSelected"
          />
        </div>
      </div>
      <Button
          name="show-modal"
          :text="$t('genios.bookmarkList.new')"
          type="button"
          @click="showCreateBookmarkModal"
          variant="primary"
          class="custom-button"
      />
    </div>

    <InfoOverlayComponent
        v-if="showCreateBookmarkModalFlag"
        :modal-data="modalData"
        type="input"
        @primary-action="createBookmarkList"
        @secondary-action="closeCreateBookmarkModal"
    />
  </div>
</template>

<script>
import Vue from "vue";
import CheckboxFormElement from "../../FormElements/CheckboxFormElement.vue";
import Button from "../../styled/Button.vue";
import ActionBar from "../common/ActionBar.vue";
import SelectedArticlesToggler from "../common/components/SelectedArticlesToggler.vue";
import InfoOverlayComponent from "../../common/InfoOverlayComponent.vue";
import WarningAlertComponent from "../../common/WarningAlertComponent.vue";
import {hasPermission} from "../../../../functions/utils/permission_utils";
import {closeFullPopupModal, closeTransparentPopupModal} from "../../../../functions/closing";
import {setupLoginComponent} from "../../../../functions/setups/vue-components";

export default Vue.extend({
  components: {
    SelectedArticlesToggler,
    ActionBar,
    Button,
    CheckboxFormElement,
    InfoOverlayComponent,
    WarningAlertComponent
  },
  data() {
    return {
      selectedLists: [],
      showCreateBookmarkModalFlag: false,
      modalData: {},
      warningVisible: false,
      warningTimer: null
    };
  },
  props: {
    watchlists: null,
  },
  computed: {
    activeWatchlistId() {
      return this.$store.state.activeWatchlistId;
    },
  },
  methods: {
    toggleSelection(watchlistId, event) {
      const watchlist = this.watchlists.find(item => item.id === watchlistId);
      if (event) {
        const index = this.selectedLists.findIndex(item => item.id === watchlistId);
        if (index === -1 && watchlist) {
          this.selectedLists.push(watchlist);
        }
      } else {
        this.selectedLists = this.selectedLists.filter(item => item.id !== watchlistId);
      }
    },
    showCreateBookmarkModal() {
      const self = this;
      this.modalData = {
        title: this.$t('genios.bookmarkList.addConfirmation.title'),
        inputPlaceholder: this.$t('genios.bookmarkList.addConfirmation.description'),
        primaryButtonText: this.$t('genios.bookmarkList.addConfirmation.confirm'),
        secondaryButtonText: this.$t('genios.bookmarkList.addConfirmation.cancel')
      };

      if (hasPermission("can_create_watch_lists")) {
        this.showCreateBookmarkModalFlag = true;
      } else {
        closeFullPopupModal(false, false);
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='web_user_login'></div>";
        openTransparentPopupModal();
        setupLoginComponent("#web_user_login", "genios.login.reasons.webUserLogin", function () {
          closeTransparentPopupModal();
          self.showCreateBookmarkModalFlag = true;
        });
      }

    },
    closeCreateBookmarkModal() {
      this.showCreateBookmarkModalFlag = false;
    },
    async createBookmarkList(bookmarkName) {
      if (bookmarkName !== "") {
        await this.$store.dispatch('addNewBookmarkList', bookmarkName);
        this.showCreateBookmarkModalFlag = false;
      } else {
        console.log("No name specified for new bookmark list")
      }
    },
    handleStandardMerklisteSelected() {
      this.warningVisible = true;
      clearTimeout(this.warningTimer);
      this.warningTimer = setTimeout(() => {
        this.warningVisible = false;
      }, 3000);
    },
    beforeDestroy() {
      clearTimeout(this.warningTimer);
    }
  }
});
</script>

<style scoped>
.list__item {
  padding: 5px;
  border-radius: 4px;
}

.checkbox-label-actionbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkbox-label-wrapper {
  display: flex;
  align-items: center;
}

.active-bookmark-list {
  border: 1px solid var(--primary-color);
  padding: 10px;
  border-radius: 4px;
  font-weight: 700;
  color: var(--foreground-color);
}

.active-bookmark-list ::v-deep .form_element__label__text {
  font-weight: 700;
  text-decoration: none;
}

.custom-label {
  margin-left: 10px;
}

.button-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 1px;
}

.button-section-divider {
  border-top: 1px solid var(--shade-color);
  margin-top: 22px;
  margin-bottom: 10px;
}

.custom-button {
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 22px;
}

.custom-button:hover {
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 22px;
}

.pill {
  display: inline-flex;
  align-items: center;
  border-radius: 25px;
  padding: 8px 14px;
  border: 1px solid var(--shade-color);
  background-color: var(--background-color);
}
</style>