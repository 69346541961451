<template>
  <div>
    <ComponentGalleryTemplate>
      <SearchInputActionbarComponent :shared-state="{}"/>
    </ComponentGalleryTemplate>
  </div>
</template>

<script>
import ComponentGalleryTemplate from "./ComponentGalleryTemplate.vue";
import SearchComponent from "../SearchComponent.vue";
import SearchInputActionbarComponent from "../ActionBar/SearchInputActionbarComponent.vue";

export default {
  name: "SimpleSearchGallery",
  components: {SearchInputActionbarComponent, ComponentGalleryTemplate, SearchComponent},
}
</script>