import {
    activateBookmarkListApi,
    addNewBookmarkListApi,
    clearBookmarkListApi,
    deleteBookmarkListApi,
    fetchNavigationItems,
    fetchSearchPurposes,
    fetchWatchLists,
    saveUserAdvancedSearchSettings,
    saveUserSearchResultSettings,
    updateBookmarkListApi,
} from "../../fetching";
import Vuex from "vuex";
import {noneSearchPurpose, SortingBy, SortingOrder} from "../../consts";
import Vue from "vue";
import persistedState from "../plugins/persistedState";
import updateSession from "../../updateSession";

Vue.use(Vuex);

const initialFilterDisplaySettings = {
    advancedSearchOpened: false,
    categoryFilterOpened: true,
    filtersOpened: true,
    userInteracted: false,
}

const initialSearchResultSettings = {
    useDecomposition: false,
    size: 10,
    sortAndOrder: {
        sort: SortingBy.BY_DATE,
        order: SortingOrder.DESCENDING
    },
    view: 'list',
    userInteracted: false,
}

const initialState = {
    meinGeniosNavItems: [],
    bookmarkList: [],
    selectedBookmarkList: {},
    activeWatchlistIds: [],
    searchPurposeList: [],
    selectedSearchPurposeId: null,
    userInteractionSearchFilterDisplaySettings: initialFilterDisplaySettings,
    searchFilterDisplaySettings: null,
    searchResultSettings: initialSearchResultSettings,
    searchResultSideNavLastPosition: null,
    showPanel: sessionStorage.getItem('showPanel'),
    currentPage: 1,
    loading: false,
    error: false,
    errorMessage: "",
    lastWebUrl: "/",
    ...window.sessionVariables?.['vuexState'] || {},
};

const store = new Vuex.Store({
    state: {...initialState},
    getters: {
        getMeinGeniosNavItems: state => state.meinGeniosNavItems,
        getBookmarkList: state => state.bookmarkList,
        getSelectedBookmarkList: state => state.selectedBookmarkList,
        getActiveWatchlistIds: (state) => state.activeWatchlistIds,
        getSearchPurposeList: state => state.searchPurposeList,
        getSelectedSearchPurposeId: state => state.selectedSearchPurposeId,
        getUserInteractionSearchFilterDisplaySettings: state => state.userInteractionSearchFilterDisplaySettings,
        getSearchFilterDisplaySettings: state => state.searchFilterDisplaySettings,
        getSearchResultSettings: state => state.searchResultSettings,
        getSearchResultSideNavLastPosition: state => state.searchResultSideNavLastPosition,
        getShowPanel: state => state.showPanel,
        getCurrentPage: state => state.currentPage,
        getLoading: state => state.loading,
        getError: state => state.error,
        getErrorMessage: state => state.errorMessage,
        getLastWebUrl: state => {
            return state.lastWebUrl;
        },
    },
    mutations: {
        setMeinGeniosNavItems(state, navItems) {
            state.meinGeniosNavItems = navItems;
        },
        setBookmarkList(state, bookmarkList) {
            state.bookmarkList = bookmarkList;
        },
        setSelectedBookmarkList(state, selectedBookmarkList) {
            let selectedBookmarkListToSave = selectedBookmarkList;
            if (typeof selectedBookmarkList === 'string') {
                const savedBookmark = state.bookmarkList.find(b => selectedBookmarkList === b.id.toString());
                if (savedBookmark) {
                    selectedBookmarkListToSave = {
                        text: `${savedBookmark.name} (${savedBookmark.numberOfBookmarks || 0})`,
                        id: savedBookmark.id,
                    };
                } else {
                    selectedBookmarkListToSave = {};
                }
            }
            state.selectedBookmarkList = selectedBookmarkListToSave;
        },
        setActiveWatchlistIds(state, ids) {
            state.activeWatchlistIds = ids;
        },
        setSearchPurposeList(state, searchPurposeList) {
            state.searchPurposeList = [noneSearchPurpose, ...searchPurposeList];
        },
        setSelectedSearchPurposeId(state, id) {
            state.selectedSearchPurposeId = id;
        },
        setUserInteractionSearchFilterDisplaySettings(state, settings) {
            state.userInteractionSearchFilterDisplaySettings = settings;
        },
        setSearchFilterDisplaySettings(state, settings) {
            state.searchFilterDisplaySettings = settings;
        },
        setSearchResultSideNavLastPosition(state, position) {
            state.searchResultSideNavLastPosition = position;
        },
        setSearchResultSettings(state, setting) {
            const newSetting = {...setting};
            if (setting.sort) {
                newSetting.sortAndOrder = {
                    sort: setting.sort,
                    order: setting.order || SortingOrder.DESCENDING,
                };
            }
            state.searchResultSettings = newSetting;
            window.sharedState.sort = newSetting.sort;
            window.sharedState.order = newSetting.order;
            window.sharedState.pageSize = newSetting.size;
            window.sharedState.view = newSetting.view;
        },
        setShowPanel(state, panel) {
            state.showPanel = panel;
        },
        setCurrentPage(state, payload) {
            state.currentPage = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setError(state, payload) {
            state.error = payload;
        },
        setErrorMessage(state, payload) {
            state.errorMessage = payload;
        },
        setLastWebUrl(state, url) {
            state.lastWebUrl = url;
        },
    },
    actions: {
        async fetchMeinGeniosNavItems({ commit }) {
            commit('setLoading', true);
            try {
                const navItems = await fetchNavigationItems();
                commit('setMeinGeniosNavItems', navItems);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchBookmarkList({ commit }) {
            commit('setLoading', true);
            try {
                let bookmarkList = await fetchWatchLists();
                bookmarkList.sort((a, b) => a.name.localeCompare(b.name));
                commit('setBookmarkList', bookmarkList);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async deleteBookmarkList({ commit, dispatch }, ids) {
            commit('setLoading', true);
            try {
                await deleteBookmarkListApi(ids);
                await dispatch('fetchBookmarkList');
                await dispatch('setInitialActiveWatchlist');
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async clearBookmarkList({ commit, dispatch }, ids) {
            commit('setLoading', true);
            try {
                await clearBookmarkListApi(ids);
                await dispatch('fetchBookmarkList');
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async updateBookmarkList({ commit, dispatch }, { id, name }) {
            commit('setLoading', true);
            try {
                await updateBookmarkListApi(id, name);
                await dispatch('fetchBookmarkList');
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        setInitialActiveWatchlist({ commit, state }) {
            const activeList = state.bookmarkList.filter(item =>
                item.categoryTags && item.categoryTags.includes('Active')
            );
            const fallbackList = state.bookmarkList.find(item => item.name === "Standard-Merkliste");

            if (activeList && activeList.length > 0) {
                commit('setActiveWatchlistIds', activeList.map((list) => list.id));
            } else if (fallbackList) {
                commit('setActiveWatchlistIds', [fallbackList.id]);
            }
        },
        async activateBookmarkList({ commit, state, dispatch }, { id, isActive }) {
            commit('setLoading', true);
            try {
                await activateBookmarkListApi(id, isActive);
                await dispatch('fetchBookmarkList');
                if (isActive) {
                    const newActiveWatchlistIds = state.activeWatchlistIds;
                    newActiveWatchlistIds.push(id);
                    commit('setActiveWatchlistIds', newActiveWatchlistIds);
                } else if (state.activeWatchlistIds.includes(id)) {
                    const newActiveWatchlistIds = state.activeWatchlistIds;
                    newActiveWatchlistIds.splice(newActiveWatchlistIds.indexOf(id), 1);
                    commit('setActiveWatchlistIds', newActiveWatchlistIds);
                }
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async addNewBookmarkList({ commit, dispatch }, listName) {
            commit('setLoading', true);
            try {
                await addNewBookmarkListApi(listName);
                await dispatch('fetchBookmarkList');
                commit('setError', false);
                commit('setErrorMessage', "");
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async fetchSearchPurposeList({ commit }) {
            commit('setLoading', true);
            try {
                const searchPurposeList = await fetchSearchPurposes();
                commit('setSearchPurposeList', [...searchPurposeList]);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async saveAdvancedSearchSettings({ commit, getters }, settingsToSave) {
            commit('setLoading', true);
            try {
                const { data } = await saveUserAdvancedSearchSettings(settingsToSave);
                if (data) {
                    commit('setSearchFilterDisplaySettings', {...data});
                    commit('setUserInteractionSearchFilterDisplaySettings', {...data, userInteracted: true});
                }
                updateSession(null, null, null);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
        async saveSearchResultSettings({ commit, getters }, settingsToSave) {
            commit('setLoading', true);
            try {
                const dataToSave = {
                    useDecomposition: settingsToSave.useDecomposition.value || settingsToSave.useDecomposition,
                    size: settingsToSave.size.value || settingsToSave.size,
                    sort: settingsToSave.sortAndOrder.sort,
                    order: settingsToSave.sortAndOrder.order,
                    view: settingsToSave.view.value || settingsToSave.view,
                };
                const { data } = await saveUserSearchResultSettings(dataToSave);
                if (data) {
                    commit('setSearchResultSettings', {...data, userInteracted: true});
                }
                updateSession(null, null, null);
            } catch (e) {
                commit('setError', true);
                commit('setErrorMessage', e.message);
            } finally {
                commit('setLoading', false);
            }
        },
    },
    plugins: [persistedState]
});

export default store;