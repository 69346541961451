<template>
  <div>
    <ComponentGalleryTemplate>
      <template #title>Background</template>
      <div class="color-gallery" style="background-color: var(--background-color)">
        <p>#FFFFFF</p>
        <p>$bg-standard: var(--background-color)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--background-color)">
        <p>#1a1a1a</p>
        <p>$bg-standard: var(--background-color)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Neutrals</template>
      <div class="color-gallery" style="background-color: var(--shade-light)">
        <p>#F5F5F5</p>
        <p>$shade-light: var(--shade-light)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--shade-light)">
        <p>#424242</p>
        <p>$shade-light: var(--shade-light)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: var(--shade-color)">
        <p>#BDBDBD</p>
        <p>$shade-color: var(--shade-color)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--shade-color)">
        <p>#BDBDBD</p>
        <p>$shade-color: var(--shade-color)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: var(--shade-dark)">
        <p>#757575</p>
        <p>$shade-dark: var(--shade-dark)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--shade-dark)">
        <p>#F5F5F5</p>
        <p>$shade-dark: var(--shade-dark)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: var(--shade-darker)">
        <p>#424242</p>
        <p>$shade-darker: var(--shade-darker)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--shade-darker)">
        <p>#F5F5F5</p>
        <p>$shade-darker: var(--shade-darker)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: var(--foreground-color)">
        <p>#000000</p>
        <p>$fg-standard: var(--foreground-color)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--foreground-color)">
        <p>#FFFFFF</p>
        <p>$fg-standard: var(--foreground-color)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <template #title>Colors</template>
      <div class="color-gallery" style="background-color: var(--primary-color)">
        <p>#F98F17</p>
        <p>$primary-color: var(--primary-color)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--primary-color)">
        <p>#F98F17</p>
        <p>$primary-color: var(--primary-color)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: var(--primary-dark)">
        <p>#F38014</p>
        <p>$primary-dark: var(--primary-dark)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--primary-dark)">
        <p>#F38014</p>
        <p>$primary-dark: var(--primary-dark)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: var(--primary-darker)">
        <p>#F38014</p>
        <p>$primary-darker: var(--primary-darker)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--primary-darker)">
        <p>#F38014</p>
        <p>$primary-darker: var(--primary-darker)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: var(--danger-color)">
        <p>#CC1D33</p>
        <p>$danger-color: var(--danger-color)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--danger-color)">
        <p>#CC1D33</p>
        <p>$danger-color: var(--danger-color)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: var(--danger-dark)">
        <p>#BF152C</p>
        <p>$danger-dark: var(--danger-dark)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--danger-dark)">
        <p>#BF152C</p>
        <p>$danger-dark: var(--danger-dark)</p>
      </div>
    </ComponentGalleryTemplate>
    <ComponentGalleryTemplate>
      <div class="color-gallery" style="background-color: var(--danger-darker)">
        <p>#B00020</p>
        <p>$danger-darker: var(--danger-darker)</p>
      </div>
      <div class="color-gallery dark-theme" style="background-color: var(--danger-darker)">
        <p>#B00020</p>
        <p>$danger-darker: var(--danger-darker)</p>
      </div>
    </ComponentGalleryTemplate>
  </div>
</template>

<script>
import ComponentGalleryTemplate from "./ComponentGalleryTemplate.vue";

export default {
  name: "ColorsGallery",
  components: {ComponentGalleryTemplate},
}
</script>

<style scoped>
.color-gallery {
  width: 25%;
  padding: 30px;
  border: 1px solid #BDBDBD;
}
</style>