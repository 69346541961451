import { render, staticRenderFns } from "./ColorConversionGallery.vue?vue&type=template&id=2aa7bb46&scoped=true"
import script from "./ColorConversionGallery.vue?vue&type=script&lang=js"
export * from "./ColorConversionGallery.vue?vue&type=script&lang=js"
import style0 from "./ColorConversionGallery.vue?vue&type=style&index=0&id=2aa7bb46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa7bb46",
  null
  
)

export default component.exports