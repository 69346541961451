<template>
  <div>
    <ComponentGalleryTemplate>
      <template #title>Tag with empty text</template>
      <active-filter-tag
          :key="'Filter'"
          :element="{
            type: 'text'
          }"
          :type="'Sprache'"
      />
    </ComponentGalleryTemplate>

    <ComponentGalleryTemplate>
      <template #title>Tag with label</template>
      <active-filter-tag
          :key="'Filter'"
          :element="{
            type: 'text',
            string: 'German'
          }"
          :type="'Sprache'"
      />
    </ComponentGalleryTemplate>

    <ComponentGalleryTemplate>
      <template #title>List of tags</template>
      <active-filters
          :shared-state="stateWithActiveFilters"
          :replace-result-on-change="false"
      />
    </ComponentGalleryTemplate>
  </div>
</template>

<script>
import Button from "../styled/Button.vue";
import CheckboxFormElement from "../FormElements/CheckboxFormElement.vue";
import RadioGroupComponent from "../FormElements/radio/RadioGroupComponent.vue";
import DropdownFormElement from "../FormElements/DropdownFormElement.vue";
import ComponentGalleryTemplate from "./ComponentGalleryTemplate.vue";
import ActiveFilterTag from "../Filters/ActiveFilterTag.vue";
import ActiveFilters from "../Filters/ActiveFilters.vue";

export default {
  name: "ButtonGallery",
  components: {
    ActiveFilters,
    ActiveFilterTag,
    ComponentGalleryTemplate,
    DropdownFormElement,
    RadioGroupComponent,
    CheckboxFormElement,
    Button
  },
  data() {
    return {
      buttonText: 'Button Text',
      stateWithActiveFilters: {
        activeFilters:
          {
            language: [
              'German', 'English'
            ]
          },
        advancedSearchParams: []
      },
    }
  },
}
</script>