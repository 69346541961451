<template>
  <div
      v-click-outside="onClickOutside"
      id="action-bar-component"
      class="action_bar_container"
      ref="actionBarContainer"
      :mode=mode
  >
    <div class="action_buttons_line">
      <select-all v-if="mode === 'searchResult' && !displayOnSmallScreen" :focus="focus" :is-all-selected="isAllSelected" @update:selected-all="updateSelected($event)"></select-all>
      <Button
          name="toggle"
          class="smaller-padding"
          :text="displayOnSmallScreen ? `${displayValues.length}` : `${displayValues.length} ${$t('genios.selected')}`"
          @click="toggleCheckedDocumentsList"
          :class="{hide: !showCounter}"
          variant="secondary"
          border="none"
          text-style="400"
          :toggle-text="false"
      >
        <ion-icon src="/icons/custom/chevron-down-outline.svg" :class="arrowClass"/>
      </Button>
      <div class="tooltip tooltip-icon" v-if="(usedInSourceView || emptyMode) && hasPermission('source')">
        <Button
            name="source"
            icon="server-outline"
            aria-label="source"
            variant="icon"
            :disabled="disabledServerIcon"
            @click="toggleWatchlistInput"
        />
        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{ $t("genios.tooltip.icons.server") }}
        </div>
      </div>
      <Button
          name="search"
          :title="$t('genios.browseSources.searchInSources')"
          v-if="usedInSourceView && showCounter"
          variant="icon"
          class="search_button"
          icon="search-outline"
          @click="toggleSearchInput"
          aria-label="search"
      />
      <div class="tooltip tooltip-icon">
        <Button
            name="print"
            icon="print-outline"
            aria-label="print"
            variant="icon"
            @click="printResultList"
        />
        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{ $t("genios.tooltip.icons.print") }}
        </div>
      </div>

      <Button
          v-if="(usedInSearchResult || emptyMode) && collapsedBar"
          icon="ellipsis-horizontal-outline"
          area-lable="expand"
          variant="icon"
          @click="collapsedBar = !collapsedBar"
      />
      <div class="tooltip tooltip-icon">
        <Button
            v-if="(usedInSearchResult || emptyMode) && !collapsedBar"
            name="mail"
            icon="mail-outline"
            aria-label="mail"
            variant="icon"
            @click="openSendMailForm"
        />
        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{ $t("genios.tooltip.icons.mail") }}
        </div>
      </div>
      <div class="tooltip tooltip-icon" >
        <Button
            v-if="usedInSearchResult && !collapsedBar && hasPermission('source')"
            name="bookmark"
            icon="bookmark-outline"
            aria-label="euro"
            variant="icon"
            @click="toggleWatchlistInput"
        />
        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">
          {{ $t("genios.tooltip.icons.bookmark") }}
        </div>
      </div>
      <div class="tooltip tooltip-icon">
        <Button
            v-if="(usedInSearchResult || emptyMode) && !collapsedBar"
            name="purchase"
            icon="eye-outline"
            aria-label="purchase"
            variant="icon"
            @click="openConfirmPurchase"
        />
        <div class="tooltiptext tooltiptext-icon tooltip-top tooltip-top-icon">{{ $t("genios.tooltip.icons.open") }}
        </div>
      </div>
      <Button
          v-if="(usedInSearchResult || emptyMode) && !collapsedBar && !defaultStateBar"
          icon="close-outline"
          area-lable="collapse"
          variant="icon"
          @click="collapsedBar = !collapsedBar"
      />
    </div>
    <select-all v-if="mode === 'searchResult' && displayOnSmallScreen" :focus="focus" :is-all-selected="isAllSelected" @update:selected-all="updateSelected($event)"></select-all>
    <div class="checked_documents_container" v-bind:class="{hide: !expanded}">
      <overlay-scrollbars
          :options="{
            scrollbars: { autoHide: 'move', clickScrolling: true, autoHideDelay: 200 },
            overflowBehavior: { x: 'hidden'}
           }"
      >
        <div class="checked_documents_list">
          <div class="checked_document_line"
               v-for="item in displayValues"
               :key="item.docId">
            <div class="checked_document_title" :title="item.source">{{ itemName(item) }}</div>
            <Button
                type="button"
                name="close"
                @click="removeItem(item.docId)"
                icon="close-outline"
                variant="icon"
            />
          </div>
        </div>
      </overlay-scrollbars>
      <Button
          name="removeAll"
          :text="$t('genios.removeAll')"
          @click="removeAll"
          variant="secondary"
          border="none"
          color="#CC1D33 !important"
          decoration="underline"
          :toggle-text="false"
      />
    </div>

    <SearchInputActionbarComponent
        v-if="usedInSourceView"
        v-show="showSearch && showCounter"
        :shared-state="sharedState"
        @closeWidget="toggleSearchInput"
    />
    <MySources
        v-if="usedInSourceView && showWatchlist"
        key="my-sources-global"
        :is-loading="disabledServerIcon"
        :shared-state="sharedState"
        :my-sources-data="sharedState.mySources"
        @close-widget="closeWatchlist"
        @documents-saved="removeAll"
    />
    <WatchlistAddComponent
        v-if="usedInSearchResult && showWatchlist"
        :shared-state="sharedState"
        :document-ids="selectedDocumentsForBookmarking"
        @closeWidget="closeWatchlist"
        @documentsSaved="removeAll"
    />
    <SendEmailComponent
        v-if="usedInSearchResult && expandedSendEmail"
        :expanded-send-email=expandedSendEmail
        :ids="getDocumentIds()"
        @closeWidget="closeSendEmail"
    />

    <ConfirmPurchaseComponent
        v-if="usedInSearchResult && expandedConfirmPurchase"
        :expanded-confirm-purchase=expandedConfirmPurchase
        :shared-state=sharedState
        @closeWidget="closeConfirmPurchase"
    />

  </div>

</template>

<script>
import vClickOutside from 'v-click-outside';
import {checkSingleDocument} from "../../../functions/checks";
import {styleActionBarPosition} from "../../../functions/stylization";
import SearchInputActionbarComponent from "./SearchInputActionbarComponent.vue";
import {runFunctionAfterLoginDialog} from "../../../functions/builds";
import WatchlistAddComponent from "../Watchlist/WatchlistAddComponent.vue";
import {OverlayScrollbarsComponent} from 'overlayscrollbars-vue';
import Button from "../styled/Button.vue";
import {notImplementedVueAlert} from "../../../functions/temp";
import {rememberCheckedDocument, rememberCheckedSources} from "../../../functions/components/selectionComponent";
import SendEmailComponent from "./SendEmailComponent.vue";
import ConfirmPurchaseComponent from "./ConfirmPurchaseComponent.vue";
import {defineSendEmailList} from "../../../functions/components/sendEmailComponent";
import {openTransparentPopupModal} from "../../../functions/opens";
import {setupLoginComponent} from "../../../functions/setups/vue-components";
import {closeTransparentPopupModal} from "../../../functions/closing";
import MySources from "../MySources/MySources.vue";
import CheckedDocuments from "./CheckedDocuments.vue";
import eventBus from "../../../eventBus";
import {hasPermission} from "../../../functions/utils/permission_utils";
import scrollMixin from "../../../functions/stylization/scrollMixin";
import {convertToZulu} from "../../../functions/utils/date_utils";
import SelectAll from "../MeinGenios/common/SelectAll.vue";

export default {
  name: "ActionBarComponent",
  mixins: [scrollMixin],
  components: {
    SelectAll,
    CheckedDocuments,
    MySources,
    Button,
    WatchlistAddComponent,
    SearchInputActionbarComponent,
    SendEmailComponent,
    ConfirmPurchaseComponent,
    'overlay-scrollbars': OverlayScrollbarsComponent
  },
  props: {
    sharedState: Object,
    mode: String,          //  supported: "sourceBrowsing", "searchResult" or empty
    sticky: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      expandedSendEmail: false,
      expandedConfirmPurchase: false,
      expanded: false,
      stopPosition: 0,
      showSearch: false,
      showWatchlist: false,
      collapsedBar: false,
      defaultStateBar: true,
      disabledServerIcon: true,
      focus: null,
      displayOnSmallScreen: window.innerWidth <= 1000,
    };
  },
  created() {
    this.MODE_SOURCE = "sourceBrowsing";
    this.MODE_SEARCH = "searchResult";
    this.MODE_NONE = "";
  },
  mounted() {
    eventBus.$on("my-sources-loader", value => {
      this.disabledServerIcon = value
    })

    eventBus.$on("close-my-sources-global", value => {
      this.showWatchlist = value
    })

    eventBus.$on('scrollEvent', this.onScroll);

    this.calculateStopPosition();
    window.addEventListener('resize', (event) => {
      this.changeStateBar();
    });
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  beforeDestroy() {
    eventBus.$off('scrollEvent', this.onScroll);
  },

  methods: {
    onScroll(scrollPosition) {
      if (this.sticky) {
        styleActionBarPosition(scrollPosition, this.stopPosition);
      }
    },
    changeStateBar: function () {
      if (window.innerWidth < 1000) {
        this.collapsedBar = this.showCounter && this.sizePanelOpened;
        this.defaultStateBar = !this.showCounter;
      } else {
        this.defaultStateBar = true;
        this.collapsedBar = false;
      }
      this.displayOnSmallScreen = window.innerWidth <= 1000;
    },
    closeMenu() {
      this.expanded = false;
    },
    closeSendEmail() {
      this.$nextTick(this.expandedSendEmail = false);
    },
    closeConfirmPurchase() {
      this.$nextTick(this.expandedConfirmPurchase = false);
    },
    getDocumentIds() {
      return defineSendEmailList();
    },
    onClickOutside() {
      this.closeMenu();
    },
    toggleCheckedDocumentsList() {
      this.expanded = !this.expanded;
      this.showSearch = false;
    },
    removeAll() {
      this.sharedSelectedItems.slice().forEach(element => {
        checkSingleDocument(element.docId, false);
        this.deleteSelectedItem(element.docId);
      });
      this.closeMenu();
    },
    removeItem(docId) {
      checkSingleDocument(docId, false);
      this.deleteSelectedItem(docId);
      if (!this.showCounter) {
        this.closeMenu();
      }
    },
    calculateStopPosition() {
      this.stopPosition = (this.$refs.actionBarContainer.offsetTop - 10);
    },
    itemName(item) {
      if (this.usedInSourceView) {
        return item.source;
      } else {
        return item.title;
      }
    },
    deleteSelectedItem(docId) {
      if (this.usedInSourceView) {
        rememberCheckedSources(docId, false, "");
      } else {
        rememberCheckedDocument(docId, false, "", "");
      }
    },
    printResultList() {
      window.print();
    },
    toggleSearchInput() {
      this.showSearch = !this.showSearch;
      this.expanded = false;
    },
    closeWatchlist() {
      this.showWatchlist = false;
    },
    toggleWatchlistInput() {
      const self = this;
      runFunctionAfterLoginDialog("genios.login.reasons.login", function () {
        self.showWatchlist = !!window.sharedState.loginStatus.loggedIn;
      });
    },
    openSendMailForm() {
      const isLoggedIn = window.sharedState.loginStatus.loggedIn;
      if (!isLoggedIn) {
        this.expandedSendEmail = false
        openTransparentPopupModal();
        const contentContainer = document.querySelector("#overlay_content");
        contentContainer.innerHTML = "<div id='embedded_login_send_email'></div>";
        const self = this;
        setupLoginComponent("#embedded_login_send_email", "genios.login.reasons.login", function () {
          closeTransparentPopupModal();
          self.expandedSendEmail = !!window.sharedState.loginStatus.loggedIn;
        });
      } else {
        this.expandedSendEmail = !this.expandedSendEmail
      }
    },
    openConfirmPurchase() {
      this.expandedConfirmPurchase = true;
    },
    notImplemented() {
      notImplementedVueAlert(this.$root);
    },
    hasPermission: function (name) {
      return hasPermission(name)
    },
    updateSelected(event) {
      this.closeMenu();
      if (!this.usedInSourceView) {
        const allDocumentOnPage = this.sharedState.searchResult || [];
        const checked = !!event;
        allDocumentOnPage.forEach(doc => {
          rememberCheckedDocument(doc.documentId, checked, doc.documentTitle, doc.source, doc.price, doc.database, doc.type, doc.year, doc.priceCategory, doc.mediaType);
          checkSingleDocument(doc.documentId, checked);
        })
      }
    }
  },
  computed: {
    arrowClass() {
      return this.expanded ? "rotate-180" : "";
    },
    isAllSelected() {
      if (this.mode === this.MODE_SEARCH) {
        const searchResult = this.sharedState.searchResult || [];
        const selectedDocuments = this.sharedState.selectedDocuments;
        return searchResult.length > 0 && searchResult.every(result => selectedDocuments.map(doc => doc.docId).includes(result.documentId));
      }
      return false;
    },
    sharedSelectedItems() {
      if (this.usedInSourceView) {
        return this.sharedState.selectedSources || [];
      } else {
        return this.sharedState.selectedDocuments || [];
      }
    },
    sizePanelOpened() {
        return this.sharedState.sizePanel || true;
    },
    displayValues() {
      return this.sharedSelectedItems || [];
    },
    showCounter() {
      return this.sharedSelectedItems.length > 0;
    },
    usedInSearchResult() {
      return this.mode === this.MODE_SEARCH;
    },
    usedInSourceView() {
      return this.mode === this.MODE_SOURCE;
    },
    emptyMode() {
      return this.mode === this.MODE_NONE;
    },
    selectedDocumentsForBookmarking() {
      return sharedState.selectedDocuments.map((doc) => {
        const docToAdd = {
          bookmarkedItem: doc.docId,
        }
        if (doc.year) {
          return {
            ...docToAdd,
            itemDate:convertToZulu(doc.year)
          }
        }
        return docToAdd;
      });
    }
  },
  watch: {
    'sharedState.selectedDocuments'(newValue) {
      this.changeStateBar();
    }
  }
};
</script>
