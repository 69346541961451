<template>
  <div>
    <ComponentGalleryTemplate>
      <search-component :shared-state="sharedState"/>
    </ComponentGalleryTemplate>
  </div>
</template>

<script>
import ComponentGalleryTemplate from "./ComponentGalleryTemplate.vue";
import SearchComponent from "../SearchComponent.vue";

export default {
  name: "SearchGallery",
  components: {ComponentGalleryTemplate, SearchComponent},
  data() {
    return {
      sharedState: {
        searchComponentSearchString: '',
        page: 'test',
        path: '/test',
        selectedBrowseSource: {
          name: 'test'
        },
        queryString: 'test'
      }
    }
  },
}
</script>