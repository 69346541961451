import { render, staticRenderFns } from "./WatchlistAddComponent.vue?vue&type=template&id=a3b5d21c&scoped=true"
import script from "./WatchlistAddComponent.vue?vue&type=script&lang=js"
export * from "./WatchlistAddComponent.vue?vue&type=script&lang=js"
import style0 from "vue-select/dist/vue-select.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./WatchlistAddComponent.vue?vue&type=style&index=1&id=a3b5d21c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3b5d21c",
  null
  
)

export default component.exports