<template>
  <div>
    <div class="image-container">
      <ComponentGalleryTemplate>
        <template #title>MenuNavigation.vue</template>
        <img src="/screenshots/navigation-menu.png" alt="Navigation Menu" class="responsive-image"/>
      </ComponentGalleryTemplate>
    </div>
  </div>
</template>

<script>
import ComponentGalleryTemplate from "./ComponentGalleryTemplate.vue";

export default {
  name: "NavigationGallery",
  components: { ComponentGalleryTemplate }
}
</script>

<style scoped>
.image-container {
  display: inline-block;
  padding: 0;
  margin: 0;
}

.responsive-image {
  display: block;
  max-width: 100%;
  height: auto;
}
</style>
